import React from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Grid from "../../../../../components/grid"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import OrdnungTask from "../../../../../content/kurse/versteckte-ordnung/02-ordnung/task"
import Poem from "../../../../../components/poem"
import PaperStack from "../../../../../components/paper-stack"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "versteckte-ordnung",
          chapterId: "02-ordnung",
          taskId: "trichter",
        })
        navigate("/kurse/versteckte-ordnung/02-ordnung/trichter/loesung")
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Christian Morgenstern: Die Trichter" />
      <Stack>
        <Stack space={3}>
          <Heading as="span">Christian Morgenstern</Heading>
          <Heading as="h1" level={2}>
            Die Trichter
          </Heading>
        </Stack>
        <Grid columns={[1, 2]}>
          <PaperStack>
            <Poem align="center" size={[3, 3, 4]}>
              <p>Zwei Trichter wandeln durch die Nacht.</p>
              <p>Durch ihres Rumpfs verengten Schacht</p>
              <p>fließt weißes Mondlicht</p>
              <p>still und heiter</p>
              <p>auf ihren</p>
              <p>Waldweg</p>
              <p>u.s.</p>
              <p>w.</p>
            </Poem>
          </PaperStack>
          <OrdnungTask />
        </Grid>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
